import React, { useState, useRef } from 'react';
import { Form as FinalForm } from 'react-final-form';
import uuid from 'react-uuid';
import { Modal, Form, Button, Tooltip } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';
import {
    formatRawDataToAddonFormData,
    formatDBDataToAddonFormData,
} from '../../../util/misc';
import { DetailsTab, AddonsTab, ReviewTab } from './Steps';
import { GeneralActionButton } from '../../ActivityFeed/SysMsgComponents';
import { ReactComponent as NextIcon } from './icons/next.svg';
import premiumBadge from '../../ActivityFeed/icons/premiumBadge.png';
// import { ReactComponent as CircleRedXIcon } from './icons/circleRedX.svg';
import css from './OfferModal.module.css';

const resetAddonFields = (form) => {
    form.batch(() => {
        form.change("addonTitle", "");
        form.change("addonDesc", "");
        form.change("addonTypeOfCharge", "");
        form.change("addonQty", "");
        form.change("addonPrice", "");
        form.change("addonMwSt", "");
    });
};

const OfferModal = (props) => {
    const {
        isOpen,
        offerModalType,
        onChangeOfferModalType,
        offerBeingUpdated,
        onClose,
        intl,
        timeZone,
        txId,
        currentListing,
        savedBookingData,
        customerName,
        providerId,
        onManageDisableScrolling,
        onSendOffer,
        onUpdateOffer,
        sendFileInProgress,
        sendFileError,
        onUploadFile,
        tempFileId,
    } = props;

    const [currentStep, setcurrentStep] = useState('details');
    const [unlockedTabs, setUnlockedTabs] = useState(['details']);
    const [addonFormOpen, setAddonFormOpen] = useState('');

    const modalRef = useRef(null);

    const scrollToTop = () => {
        modalRef.current.scrollIntoView({
            // behavior: "smooth"
        });
    };

    const initialStep1Values = offerBeingUpdated ?
        formatDBDataToAddonFormData(offerBeingUpdated) :
        savedBookingData && savedBookingData.bookingStartDate ?
            formatRawDataToAddonFormData(savedBookingData) : {};

    const currentOfferAddons = offerBeingUpdated?.addons;
    const listingAddons = currentListing.attributes.publicData.addOns || [];
    const addOns = currentOfferAddons ? currentOfferAddons : listingAddons;

    const initialStep3Values = offerBeingUpdated ? {
        cancellationPolicy: offerBeingUpdated.cancellationPolicy,
        agreeTnC: true,
    } : {};

    const onCancel = () => {
        onClose();
        setcurrentStep('details');
        setUnlockedTabs(['details']);
    };

    const formatAndSubmit = (values) => {
        const {
            // shared
            endDate,
            endTime,
            startDate,
            startTime,
            noOfGuests,
            spaceRentalPrice,

            // default offer
            addons = [],
            cancellationPolicy,
            disableSpaceRentalPrice,
            payinTotal,

            // file offer
            attachedFileId,
            taxAmount,
        } = values;

        const sharedValues = {
            tx_id: txId.uuid,
            user_id: providerId.uuid,
            startDate: startDate.date,
            endDate: endDate.date,
            startTime: startTime,
            endTime: endTime,
            noOfGuests: noOfGuests,
            totalPrice: Number(spaceRentalPrice) * 100,
        };

        const specificValues = offerModalType == "default" ? {
            disableRentalFee: disableSpaceRentalPrice ? true : false,
            addons: Array.isArray(addons) ? addons : [],
            cancellationPolicy: cancellationPolicy,
            offerType: "default",
        } : {
            attachedFileId,
            tax: Number(taxAmount) * 100,
            offerType: "file",
        };

        if (offerBeingUpdated) {
            specificValues.offer_id = offerBeingUpdated._id;
            delete specificValues.offerType;
            delete specificValues.attachedFileId;
        };

        const submitValues = {
            ...sharedValues,
            ...specificValues,
        };

        onCancel();
        if (offerBeingUpdated) {
            onUpdateOffer(submitValues);
        } else {
            onSendOffer(submitValues, payinTotal);
        };
    };

    return <div className={css.root}>
        <Modal
            id="OfferModal"
            isOpen={isOpen}
            onClose={onCancel}
            onManageDisableScrolling={onManageDisableScrolling}
            containerClassName={css.modalContainer}
            usePortal
        >
            <FinalForm
                onSubmit={(e) => formatAndSubmit(e)}
                initialValues={{}}
                render={(formRenderProps) => {
                    const {
                        disabled,
                        invalid,
                        updateInProgress,
                        values,
                        form,
                        handleSubmit,
                        onMonthChanged,
                    } = formRenderProps;

                    const submitInProgress = updateInProgress;
                    const submitDisabled = invalid ||
                        disabled ||
                        submitInProgress ||
                        (offerModalType == "file" && !values.attachedFileId) ||
                        (currentStep == "addons" && values.addons?.length == 0 && values.disableSpaceRentalPrice);

                    const onResetAddon = () => {
                        setAddonFormOpen('');
                        resetAddonFields(form);
                    };

                    const onSaveAddon = () => {
                        if (addonFormOpen == 'new') {
                            const newAddon = {
                                description: values.addonDesc,
                                price: {
                                    amount: values.addonPrice.amount,
                                    currency: values.addonPrice.currency,
                                },
                                title: values.addonTitle,
                                typeOfCharge: values.addonTypeOfCharge,
                                qty: values.addonQty,
                                mwst: values.addonMwSt,
                                uuid: uuid(),
                            };
                            const allAddons = [...values.addons, newAddon];
                            form.change("addons", allAddons);
                            onResetAddon();

                        } else {
                            const newAddon = {
                                description: values.addonDesc,
                                price: {
                                    amount: values.addonPrice.amount,
                                    currency: values.addonPrice.currency,
                                },
                                title: values.addonTitle,
                                typeOfCharge: values.addonTypeOfCharge,
                                qty: values.addonQty,
                                mwst: values.addonMwSt,
                                uuid: addonFormOpen,
                            };
                            const remainingAddons = values.addons.filter(addon => addon.uuid !== addonFormOpen);
                            const allAddons = [...remainingAddons, newAddon];
                            form.change("addons", allAddons);
                            onResetAddon();
                        };
                    };

                    const onChangeTab = (goToTab) => {
                        if (currentStep !== goToTab) {
                            // const currentValues = values;
                            setcurrentStep(goToTab);
                            // form.reset(currentValues);

                            if (!unlockedTabs.includes(goToTab)) {
                                setUnlockedTabs([...unlockedTabs, goToTab]);
                            };
                        };
                    };

                    const TabControl = (params) => {
                        const { tab } = params;
                        return <span
                            style={currentStep == tab ? { color: '#30A64A' } : {}}
                        >
                            <FormattedMessage id={`OfferModal.${tab}Tab`} />
                        </span>
                        // return <span
                        //     style={currentStep == tab ? { color: '#30A64A' } : unlockedTabs.includes(tab) || offerBeingUpdated ? { cursor: 'pointer' } : {}}
                        //     onClick={() => {
                        //         if (unlockedTabs.includes(tab) || offerBeingUpdated) {
                        //             onChangeTab(tab);
                        //         };
                        //     }}
                        // >
                        //     <FormattedMessage id={`OfferModal.${tab}Tab`} />
                        // </span>
                    };

                    return <Form onSubmit={handleSubmit}>
                        <div
                            ref={modalRef}
                            className={css.topActionsWrapper}
                        >
                            {offerModalType == "default" && !offerBeingUpdated ? <GeneralActionButton
                                type="confirmInverted"
                                textId="ActivityFeed.sysMsg.providerAction.uploadOffer"
                                onClick={() => {
                                    form.reset();
                                    onChangeOfferModalType("file");
                                    setcurrentStep("details");
                                }}
                                badge={<Tooltip
                                    messageId="ActivityFeed.sysMsg.providerAction.uploadOfferBadgeTooltip"
                                    icon={<img
                                        src={premiumBadge}
                                        style={{ width: '20px', marginLeft: "-10px" }}
                                    />}
                                />}
                            /> : null}
                        </div>
                        {offerModalType == "default" ? <div className={css.navWrapper}>
                            <TabControl tab="details" />
                            <NextIcon />
                            <TabControl tab="addons" />
                            <NextIcon />
                            <TabControl tab="review" />
                        </div> : null}

                        {currentStep == 'details' && <DetailsTab
                            intl={intl}
                            offerModalType={offerModalType}
                            initialStep1Values={initialStep1Values}
                            values={values}
                            form={form}
                            txId={txId}
                            providerId={providerId}
                            onMonthChanged={onMonthChanged}
                            sendFileInProgress={sendFileInProgress}
                            sendFileError={sendFileError}
                            onUploadFile={onUploadFile}
                            tempFileId={tempFileId}
                        />}

                        {currentStep == 'addons' && <AddonsTab
                            addOns={addOns}
                            intl={intl}
                            values={values}
                            form={form}
                            addonFormOpen={addonFormOpen}
                            setAddonFormOpen={setAddonFormOpen}
                        />}

                        {currentStep == 'review' && <ReviewTab
                            intl={intl}
                            form={form}
                            timeZone={timeZone}
                            values={values}
                            listingId={currentListing.id}
                            customerName={customerName}
                            activity={savedBookingData?.activity}
                            initialStep3Values={initialStep3Values}
                        />}

                        <div className={css.btnContainer}>
                            {addonFormOpen ? <div className={css.addonBtnsWrapper}>
                                <a onClick={() => onResetAddon()}>
                                    Cancel
                                </a>
                                <Button
                                    className={css.nextButton}
                                    type="button"
                                    inProgress={submitInProgress}
                                    disabled={submitDisabled}
                                    onClick={() => onSaveAddon()}
                                >
                                    Save
                                </Button>
                            </div> : currentStep !== "review" && offerModalType == "default" ? <Button
                                className={css.nextButton}
                                type="button"
                                inProgress={submitInProgress}
                                disabled={submitDisabled}
                                onClick={() => {
                                    if (currentStep == 'details') {
                                        onChangeTab('addons');
                                    } else {
                                        onChangeTab('review');
                                    };
                                    scrollToTop();
                                }}
                            >
                                <FormattedMessage
                                    id={currentStep == 'details' ? "OfferModal.continueToAddons" : "OfferModal.continueToReview"}
                                />
                            </Button> : <Button
                                className={css.nextButton}
                                type="button"
                                onClick={() => handleSubmit(values)}
                                inProgress={submitInProgress}
                                disabled={submitDisabled}
                            >
                                <FormattedMessage id={offerBeingUpdated ?
                                    "OfferModal.updateIndividualOffer" :
                                    "OfferModal.submitIndividualOffer"}
                                />
                            </Button>}
                        </div>
                    </Form>
                }}
            />
        </Modal>
    </div>
};

export default OfferModal;
